 
// assets
import colors from '../public/assets/scss/themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import customShadows from './shadows';
import { createTheme } from '@mui/material';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {

    const themeOption = {
        colors: colors,
        mode:'dark',
        heading: '',
        paper: '',
        backgroundDefault: '',
        background: '',
        darkTextPrimary: '',
        darkTextSecondary: '',
        textDark: '',
        menuSelected: '',
        menuSelectedBack: '',
        divider: '',
        customization
    };

    switch (customization.mode) {
        case 'dark':
            themeOption.colors = colors;
            themeOption.mode = 'dark';

            themeOption.paper = colors.darkLevel2;
            themeOption.backgroundDefault = colors.darkLevel2;
            themeOption.background = colors.darkBackground;
            themeOption.darkTextPrimary = colors.darkTextPrimary;
            themeOption.darkTextSecondary = colors.darkTextSecondary;
            themeOption.textDark = colors.darkTextPrimary;
            themeOption.menuSelected = colors.darkSecondaryMain;
            themeOption.menuSelectedBack = colors.darkSecondaryMain + 15;
            themeOption.divider = colors.darkTextPrimary;
            themeOption.heading = colors.darkTextTitle;
            break;
        case 'light':
            themeOption.colors = colors;
            themeOption.mode = 'light';

            themeOption.paper = colors.paper;
            themeOption.backgroundDefault = colors.paper;
            themeOption.background = colors.primaryLight;
            themeOption.darkTextPrimary = colors.grey700;
            themeOption.darkTextSecondary = colors.grey500;
            themeOption.textDark = colors.grey900;
            themeOption.menuSelected = colors.secondaryDark;
            themeOption.menuSelectedBack = colors.secondaryLight;
            themeOption.divider = colors.grey200;
            themeOption.heading = colors.grey900;
            break;
        default:
        break;
        }

    return createTheme({

        direction: customization.rtlLayout ? 'rtl' : 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920
            }
        },
        typography: themeTypography(themeOption),
        customShadows: customShadows(customization.mode, themeOption),
        components: componentStyleOverrides(themeOption)
    });
};

export default theme;